@import '../../scss/variables';

.container {
  position: relative;
  width: 100%;
  background-color: $color-secondary;
}

.appBar {
  max-width: 100vw;
}

.toolbar {
  height: 60px;
  min-height: 60px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
}

.appName {
  margin: 0 11px 0 22px;
  text-transform: uppercase;
  color: $color-primary-second;
  font-weight: bold;
}

.divider {
  margin: 0 16px 0 16px;
}