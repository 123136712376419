$color-primary: #019cdc;
$color-secondary: #ffffff;
$color-primary-second: #333333;
$color-royal-blue: rgb(4, 51, 120);

$color-text: #162B4D;
$color-text-light: #cccccc;
$color-text-inverse: #ffffff;
$color-text-dark: rgb(27, 27, 27);

$color-background-card: #f5f5f5;
$color-background-dark: #dddedf;

$color-journey-track-border: #aeaeae;
$color-journey-email-step-active: #01a3e2;
$color-journey-connection-ok: #55dd99;
$color-journey-drop-ok: rgba(85, 221, 153, 0.2);
$color-journey-connection-invalid: #ff6060;
$color-journey-drop-invalid: rgba(255, 96, 96, 0.2);

$z-index-popover: 1350;
$z-index-journey-drag-layer: 100;
$z-index-journey-node: 5;
$z-index-journey-toolbar: 10;
