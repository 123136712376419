.tabsContainer {
  flex: 2;
}

.tabs {
  // flex-grow: 1;
  height: 62px;
  :global(.MuiTabs-flexContainer) {
    height: 60px;
  }

  .links {
    font-weight: bold;
    letter-spacing: -0.25px !important;
  }
}
