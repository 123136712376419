.file-input {
  display: none;
}

.file-upload-btn {
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #4aa1f3;
  font-weight: bold;
  padding: 8px 15px;
  margin-bottom: 5px;
}

.container p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 444px;
  background-color: #f5f5f5;
  height: 250px;
  border: 1px dashed #d1c8c8;
  border-radius: 4px;
}

.upload-icon {
  width: 50px;
  height: 50px;
  /* background: url(../images/upload.png) no-repeat center center; */
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message p {
  font-weight: normal;
  font-size: 12px;
  color: #009de2;
  text-decoration: underline;
  text-align: center;
  margin: 0;
}

.drop-message span {
  text-decoration: none;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  display: block;
}

.drop-message {
  cursor: pointer;
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}

.file-display-container {
  position: fixed;
  width: 805px;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  /* background: url(../images/generic.png) no-repeat center center; */
  background-size: 100%;
  position: absolute;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  margin-right: 5px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}

.modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.modal .modal-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.modal-image-text {
  position: absolute;
  color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.upload-modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.upload-modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.progress-container {
  background: white;
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.progress-container span {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 20px;
}

.progress {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #efefef;
  height: 20px;
  border-radius: 5px;
}

.progress-bar {
  position: absolute;
  background-color: #4aa1f3;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.error {
  color: red;
}

.uploadtitle {
  display: block;
  font-weight: normal;
  font-size: 14px;
  background-color: transparent;
  text-align: left;
}

.filesection .container {
  width: 444px;
  margin: auto;
  background-color: #f5f5f5;
  height: 250px;
}

.filesection .buttonwrapper:before {
  content: '';
  width: 100%;
  display: block;
  height: 2px;
  background-color: #d2d3d4;
  box-shadow: 0px 2px 2px 0px #d2d3d4;
}

.filesection .buttonwrapper {
  height: 105px;
}

.ftitle {
  display: block !important;
  padding: 0 !important;
  background-color: transparent !important;

  font-weight: normal !important;
  font-size: 14px !important;
  color: #333 !important;
  width: 444px;
  text-align: left;
}

.uploadfile {
  background-color: #f5f5f5;
  height: 250px;
}

.uploadfile img {
  margin-top: 58px;
}

.text-info span {
  font-weight: normal;
  font-size: 12px;
  color: #009de2;
  text-decoration: underline;
}

.save-button {
  color: #fff;
  background-color: #009ddd;
  width: 444px;
  margin: 30px auto;
  display: block;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.save-button:hover,
.save-button:focus {
  background-color: #009ddd;
}

.file-list {
  width: 444px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reduced-padding {
  padding: 20px 0 0px 0;
  div {
    margin: 0 !important;
    // margin-bottom: 6px !important;
  }
}

.file-action-div {
  background-color: #f5f5f5;
  font-weight: normal;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #dddedf;
  padding: 6px 12px;
  color: #333;
  .file-title {
    margin: 0;
    flex: 1;
    text-align: start;
  }

  .download-icon {
    height: 18px;
    width: 20px;
    margin-right: 8px;
    cursor: pointer;
  }

  .delete-button {
    height: 20px;
    width: 22px;
    cursor: pointer;
  }
}

.progress-wrapper {
  max-width: 345px;
  margin: -40px auto 10px;
  border: 1px solid #dddedf;
  border-radius: 4px;
  box-shadow: 0 0 5px 2px #dddedf;
  background-color: #fff;
  padding-top: 10px;
}

.progress-wrapper .file-name {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 67%;
}

.file-name .backup-icon svg {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.cancel-button {
  border: 1px solid #dddedf;
  border-radius: 4px;
  box-shadow: 0 0 5px 2px #dddedf;
  background-color: #fff;
  font-weight: bold;
  width: 81px;
  font-size: 14px;
  height: 24px;
  text-transform: uppercase;
  cursor: pointer;
}

.progress-wrapper div {
  text-align: left;
  margin: 5px 0 !important;
  font-weight: normal;
  font-size: 12px;
  color: #333;
}

.file-upload-status {
  padding: 0 15px;
}

.file-upload-status > div {
  height: 4px !important;
}

.file-upload-status > div > div {
  background-color: #f5ac25 !important;
  text-align: left !important;
  position: relative;
}

.file-upload-status > div > div span {
  position: absolute;
  left: 60px;
  top: 4px;
  color: #333 !important;
}
