@import '../../../../scss/variables';

  .popper {
    width: 500px;
    box-shadow: 2px 4px 12px -3px #e5eced;
    .paper {
      border-radius: 10px;
      height: calc(95vh - 60px);
      max-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &::after {
      content: ' ';
      position: absolute;
      top: 0px;
      right: 26%;
      margin-top: -20px;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent $color-secondary transparent;
    }
  }
  .menuList {
    padding-bottom: 0px;
    padding-top: 0px;
    overflow-y: scroll;
    -ms-overflow-style: none;

    .menuItem {
      border-bottom: 1px solid #93edf4;
    }
    .menuItemDiv {
      width: 100%;
      .topDiv {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .campaignName {
          white-space: initial;
          font-weight: bold;
          opacity: 0.4;
          font-size: 14px;
        }
      }

      .body {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
        white-space: normal;
      }

      .bottomDiv {
        width: 100%;
        white-space: initial;
        font-weight: normal;
        color: #000000;
        font-size: 14px;
      }
    }
  }
  .notificationIcon {
    width: 17px;
    height: 19px;
    cursor: pointer;
  }
