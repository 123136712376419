//globa css change here
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  overflow: hidden !important;
  font-family: Karbon, sans-serif;
  letter-spacing: -0.25px !important;
}

.landing-wrapper {
  background-size: cover;
  background-repeat: repeat;
  background-position: top left;
  flex: auto;
  overflow: auto;
  padding-bottom: 10px;
  background: rgb(170,200,226);
  background: linear-gradient(45deg, rgba(170,200,226,1) 0%, rgba(172,204,229,1) 20%, rgba(188,214,234,1) 30%, rgba(189,222,241,1) 70%, rgba(199,228,243,1) 80%, rgba(190,226,244,1) 100%);
}

button {
  &[disabled] {
    color: rgba(#333, 0.5) !important;
    cursor: default !important;
  }
}

.dx-button-content {
  svg {
    vertical-align: middle;
  }
}