.userIcon {
  .MuiAvatar-root {
    width: 32px;
    height: 32px;
  }
}

.logo-div {
  width: 393px;
  flex: 1;
}

.action-div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .support-icon {
    margin-left: 20px;
    width: 30px;
    height: 39px;
    color: #333;
    cursor: pointer;
  }
  .select-role {
    margin-right: 16px;
  }
  .user-role {
    margin-right: 10px;
  }
}
