.contact-support-form {
    min-width: 580px;
    padding: 40px;

    .contact-support-title {
        margin-top: 0;
    }

    .contact-support-actions {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .file-details-div {
        margin-top: 10px;
        background-color: #f5f5f5;
        font-weight: normal;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #dddedf;
        padding: 6px 12px;
        color: #333;
    
        .file-title {
            margin: 0;
            flex: 1;
            text-align: start;
        }
    
        .delete-button {
            height: 20px;
            width: 22px;
            cursor: pointer;
        }
    }
}
